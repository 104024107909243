import gpl from 'graphql-tag';

const ConciliacionModel = {
    createConciliacion: (client, periodo, conciliaciones, bancos, fechaInicial, saldoInicial, cuenta_financiera_id) => {
        return client.mutate({
            mutation: gpl`
                mutation createConciliacion(
                    $periodo: String!
                    $conciliaciones: [ConciliacionInputType!]!
                    $bancos: [BancoInputType!]!
                    $fechaInicial: String!
                    $saldoInicial: Float!
                    $cuenta_financiera_id: String!
                ) {
                    createConciliacion(
                        periodo: $periodo
                        conciliaciones: $conciliaciones
                        bancos: $bancos
                        fechaInicial: $fechaInicial
                        saldoInicial: $saldoInicial
                        cuenta_financiera_id: $cuenta_financiera_id
                    ) {
                        message
                    }
                }
            `,
            variables: {
                periodo,
                conciliaciones,
                bancos,
                fechaInicial,
                saldoInicial,
                cuenta_financiera_id
            }
        }).then(response => {
            // Log the response
            //console.log("createConciliacion response:", response);
            return response;
        }).catch(error => {
            // Log the error
            //console.error("createConciliacion error:", error);
            throw error;
        });
    },
    getConciliaciones: (client, cuenta_financiera_id) => client.query({
        query: gpl`query getConciliacionesApiDrive($cuenta_financiera_id: String){
            getConciliacionesApiDrive(cuenta_financiera_id: $cuenta_financiera_id){
                fechaInicial
                saldoInicial
                iguales{
                    id
                    abono
                    cargo
                    descripcion
                    fecha
                    numeroOp
                    tipoOp
                    validada
                }
            }
        }`,
        variables: {
            cuenta_financiera_id
        }
    }),
    actualizarConciliacion: (client, conciliacion, cuenta_financiera_id) => client.mutate({
        mutation: gpl`mutation actualizarConciliacion (
            $conciliacion: ConciliacionInputType!
            $cuenta_financiera_id: String!
        ){
            actualizarConciliacion(
                conciliacion: $conciliacion
                cuenta_financiera_id: $cuenta_financiera_id
            )
        }`,
        variables: {
            conciliacion,
            cuenta_financiera_id
        }
    }),
    actualizarSaldoFechaInicial: (client, saldoInicial, fechaInicial, cuenta_financiera_id) => client.mutate({
        mutation: gpl`mutation actualizarSaldoFechaInicial(
            $saldoInicial: Float!
            $fechaInicial: String!
            $cuenta_financiera_id: String!
        ){
            actualizarSaldoFechaInicial(
                saldoInicial: $saldoInicial
                fechaInicial: $fechaInicial
                cuenta_financiera_id: $cuenta_financiera_id
            )
        }`,
        variables: {
            saldoInicial, fechaInicial, cuenta_financiera_id
        }
    }),
    getResumen: (client, bancos, cuenta_financiera_id) => client.query({
        query: gpl`query getResumenApiDrive($bancos: [BancoType!]!, $cuenta_financiera_id: String){
            getResumenApiDrive(bancos: $bancos, cuenta_financiera_id: $cuenta_financiera_id){
                fechaInicial
                saldoInicial
                iguales{
                    abono
                    cargo
                    descripcion
                    fecha
                    numeroOp
                    tipoOp
                    validada
                }
                enConciliacionesNoEnBancos{
                    abono
                    cargo
                    descripcion
                    fecha
                    numeroOp
                    tipoOp
                    validada
                }
                enBancosNoEnConciliaciones{
                    fecha
                    descripcion
                    numeroOp
                    tipoOp
                    debe
                    haber
                }
            }
        }`,
        variables: {
            bancos,
            cuenta_financiera_id
        },
        fetchPolicy: 'no-cache'
        }),

    eliminarItemConciliacion: (client, conciliacion, cuenta_financiera_id) => client.mutate({
        mutation: gpl`mutation eliminarItemConciliacion($conciliacion: ConciliacionInputType!, $cuenta_financiera_id: String){
            eliminarItemConciliacion(conciliacion: $conciliacion, cuenta_financiera_id: $cuenta_financiera_id)
        }`,
        variables: {
            conciliacion,
            cuenta_financiera_id
        }
    }),

    delete: (client, cuenta_financiera_id) => client.mutate({
        mutation: gpl`mutation eliminarConciliacion($cuenta_financiera_id: String){
            eliminarConciliacion(cuenta_financiera_id: $cuenta_financiera_id)
        }`,
        variables: {
            cuenta_financiera_id
        }
    })
}

export default ConciliacionModel;